import React from 'react'
import { Link } from 'gatsby'
import { Document } from 'react-pdf'

import Layout from '../components/layout'
import Head from '../components/head'
import aboutStyles from './about.module.scss'

const AboutPage = () => {
  return (
    <div>
      <Layout>
      <Head title="About" />
      <h1>About</h1>
      <div className={aboutStyles.content} >
      <p>I am a full stack software engineer who loves learning and practically applying new technologies.</p>
      <p>With a technical background in civil engineering, having accomplished a software engineering immersive program, and hands-on experience using multiple languages to create and implement software applications, I am confident I can be an asset to your team.</p>
      <p>Critical thinking and problem solving with the big picture in mind has been a common theme throughout much of my professional experience. Whether executing a mission in Afghanistan or completing a construction project on time for a customer; ensuring the outcome is successful can be attributed to a goal-oriented mindset.</p>
      <p>My past experiences have instilled this characteristic in me and regardless of the resources provided, I will find a way to deliver the required outcomes. This valuable attribute is now applied within my role as a software engineer: tasks are assigned to me where I often have to learn new languages and databases on short notice, and I am still able to deliver a quality product.</p>
      <Link className={aboutStyles.contactLink} target="_blank" rel="noopener noreferrer" to="https://drive.google.com/file/d/1xUEf0v2ERA-3QZ6om9d8OmJQ5ZqmBzOL/view?usp=sharing">Check out my full professional resume here.</Link><br/><br/>
      <Link className={aboutStyles.contactLink} to="/contact" >Want to work with me? Reach out by clicking here.</Link>
      </div>
      </Layout>
    </div>
  )
}

export default AboutPage